import { Amplify, Auth, Hub } from 'aws-amplify';
import { ApplicationState, useAppStore } from '../store';
import InAppBrowser, { RedirectResult } from 'react-native-inappbrowser-reborn';
import { Linking, Platform } from 'react-native';
import { TenantFeature, TenantTuple } from '../types/AuthTypes';
import { getTokenRaw, signOutUser } from '../helpers/AuthHelper';
import { useCallback, useEffect, useState } from 'react';

import { ApiError } from '../types/ApiResponse';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Config } from '../constants/Config';
import { DataSharingTenant } from '../types/DataSharingTenant';
import { ProviderName } from '../types/CognitoUser';
import { UserAttributes } from '../types/UserAttributes';
import UserService from '../../api/user';
import { ValidateError } from '../../core';
import { addRawAnalyticsLog } from '../helpers/AddRawAnalyticsLog';
import { setValue } from 'src/api/storage';
import { useNotifications } from './useNotifications';
import { DataSharingRequest } from '../types/DataSharingRequest';

export enum SocialVariant {
  // fbauth = 'fbauth',
  googauth = 'googauth',
  // twitauth = 'twitauth',
  appleAuth = 'appleAuth'
}

interface UseUser {
  signInByEmail: (email: string, password: string) => Promise<any>;
  signInBySocial: (type: SocialVariant) => Promise<any>;
  signOut: (global?: boolean) => Promise<any>;
  getToken: () => Promise<string>;
  deleteUserAccount: () => Promise<boolean>;
  getProviderName: () => Promise<ProviderName | null>;
}

export function useUser(): UseUser {
  const signInByEmail = (email: string, password: string): Promise<any> => {
    return Auth.signIn(email, password);
  };

  const signOut = useCallback(signOutUser, []);

  const getToken = useCallback(getTokenRaw, []);

  const deleteUserAccount = async (): Promise<boolean> => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user === undefined) {
        // User not logged in
        return false;
      }

      return await new Promise<boolean>((resolve) => {
        user.deleteUser((error) => {
          if (error) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      });
    } catch (error) {
      addRawAnalyticsLog('Error', { file: 'useUser.tsx', function: 'deleteUser', data: error });
    }
    return false;
  };

  const signInBySocial = (type: SocialVariant): Promise<any> => {
    if (type === SocialVariant.appleAuth) {
      return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
    } else if (type === SocialVariant.googauth) {
      return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    }
    return Promise.reject();
  };

  const getProviderName = useCallback(async (): Promise<ProviderName | null> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoProperties = cognitoUser.attributes.identities;
    if (cognitoProperties) {
      return JSON.parse(cognitoUser.attributes.identities)[0].providerName;
    }

    return null;
  }, []);

  return { signInByEmail, signOut, getToken, deleteUserAccount, signInBySocial, getProviderName };
}

const initAmplify = (): boolean => {
  const urlOpener = async (url: any, redirectUrl: any) => {
    await InAppBrowser.isAvailable();
    const ephemeralWebSession = String(url).indexOf('logout') > 0;
    const { type, url: newUrl } = (await InAppBrowser.openAuth(url, redirectUrl, {
      showTitle: false,
      enableUrlBarHiding: true,
      enableDefaultShare: false,
      ephemeralWebSession: ephemeralWebSession
    })) as RedirectResult;

    if (type === 'success') {
      Linking.openURL(newUrl);
    }
  };

  try {
    Amplify.configure({
      Auth: {
        identityPoolId: Config.COGNITO_IDENTITY_POOL_ID,
        region: Config.AWS_REGION,
        userPoolId: Config.COGNITO_USER_POOL_ID,
        userPoolWebClientId: Config.COGNITO_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
          domain: Config.COGNITO_DOMAIN,
          scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid', 'phone'],
          redirectSignIn: Platform.OS === 'web' ? Config.COGNITO_REDIRECT_SIGNIN_WEB : Config.COGNITO_REDIRECT_SIGNIN,
          redirectSignOut:
            Platform.OS === 'web' ? Config.COGNITO_REDIRECT_SIGNOUT_WEB : Config.COGNITO_REDIRECT_SIGNOUT,
          responseType: 'code',
          options: {
            AdvancedSecurityDataCollectionFlag: false
          },
          urlOpener: Platform.OS === 'web' ? undefined : urlOpener
        }
      },
      Storage: {
        AWSS3: {
          bucket: Config.USER_FILES_S3_BUCKET,
          region: Config.AWS_REGION
        }
      },
      Analytics: {
        disabled: false
      }
    });
  } catch (error) {
    console.error(`initAmplify: ${JSON.stringify(error)}`);
    return false;
  }

  return true;
};

export const validUserInfoResponse = (data: any): UserAttributes => {
  for (const i of Object.keys(data)) {
    if (i !== 'picture' && i !== 'birthdate' && i !== 'createdOn' && data[i] == null) {
      throw Error('Null value in user info');
    }
  }

  return {
    name: data.name,
    nickname: data.nickname,
    email: data.email,
    gender: data.gender,
    birthdate: data.birthdate,
    picture: data.picture,
    sub: data.cognitoId,
    userId: data.id,
    publicEmail: data.publicEmail
  };
};

export const useUserInitializer = (): boolean => {
  const [isLoginModuleInit, setIsLoginModuleInit] = useState<boolean>(false);
  const isAppInit = useAppStore((state: ApplicationState) => state.isAppPreInit);

  useEffect(() => {
    if (isAppInit) {
      initAmplify();
      setIsLoginModuleInit(true);
    }
  }, [isAppInit]);

  return isLoginModuleInit;
};

export const useUserLoginInitializer = (): boolean => {
  const isAppInit = useAppStore((state: ApplicationState) => state.isAppPreInit);
  const isAppInitComplete = useAppStore((state: ApplicationState) => state.isAppInitComplete);
  const setUser = useAppStore((state: ApplicationState) => state.setUser);
  const setUserLogged = useAppStore((state: ApplicationState) => state.setUserLogged);
  const setAuthLoading = useAppStore((state: ApplicationState) => state.setAuthLoading);
  const changeUserAttributes = useAppStore((state: ApplicationState) => state.changeUserAttributes);
  const clearUser = useAppStore((state: ApplicationState) => state.clearUser);
  const [isLoginModuleInit, setIsLoginModuleInit] = useState<boolean>(false);
  const setAutoLogin = useAppStore((state: ApplicationState) => state.setAutoLogin);
  const { removeExternalUserId } = useNotifications();

  const loadUserData = useCallback(async () => {
    const token = await getTokenRaw();
    const user = await Auth.currentAuthenticatedUser();

    const userInfo: UserAttributes = {
      name: user.attributes.name,
      nickname: user.attributes.nickname,
      email: user.attributes.email,
      gender: user.attributes.gender,
      birthdate: user.attributes.birthdate,
      sub: user.attributes.sub,
      height: undefined,
      weight: undefined,
      smoker: undefined,
      bloodpressureMedication: undefined,
      hypertension: undefined,
      diabetic: undefined,
      activityLevel: undefined
    };

    let savedUser: UserAttributes = userInfo;

    try {
      const response = await UserService.getUserInfo().catch(() => UserService.addUser(userInfo));
      savedUser = validUserInfoResponse(response);
    } catch (error) {
      //TODO add log to Analitics
      console.error('getUserInfo:', error);
      return false;
    }

    changeUserAttributes({ ...savedUser, ...userInfo });

    setUser(savedUser.userId ?? '', user.attributes.sub, token, user.attributes.email);
  }, [changeUserAttributes, setUser]);

  const processSignInUp = useCallback(async () => {
    await loadUserData();
    setUserLogged(true);
  }, [loadUserData, setUserLogged]);

  const processSignOut = useCallback(async () => {
    try {
      //TODO move to module Notification
      await removeExternalUserId();
    } catch (error) {
      console.error(`removeExternalUserId: ${JSON.stringify(error)}`);
    }

    await setValue('tenantKey', '');
    clearUser();
  }, [clearUser, removeExternalUserId]);

  useEffect(() => {
    if (isAppInitComplete) {
      setAuthLoading(false);
      setAutoLogin(false);
    }
  }, [isAppInitComplete, setAuthLoading, setAutoLogin]);

  useEffect(() => {
    if (isAppInit) {
      initAmplify();

      Auth.currentAuthenticatedUser()
        .then(() => {
          return loadUserData().then(() => {
            setAutoLogin(true);
            setUserLogged(true);
          });
        })
        .catch((error) => {
          console.error(`currentAuthenticatedUser: ${JSON.stringify(error)}`);
          console.error(`currentAuthenticatedUser: ${error}`);
        })
        .finally(() => {
          setIsLoginModuleInit(true);
        });

      const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
        switch (event) {
          case 'signIn':
            processSignInUp();
            break;
          case 'signOut':
            processSignOut();
            break;
          case 'customOAuthState':
            break;
        }
      });

      return unsubscribe;
    }
  }, [
    isAppInit,
    clearUser,
    loadUserData,
    processSignInUp,
    processSignOut,
    setUserLogged,
    setAuthLoading,
    setAutoLogin
  ]);

  return isLoginModuleInit;
};

export const useIsUserLogged = (): boolean => {
  return useAppStore((state: ApplicationState) => state.isLogged);
};

export const useIsUserAutoLogin = (): boolean => {
  return useAppStore((state: ApplicationState) => state.isAutoLogin);
};

// export const useGetInternalUserId = (): string | undefined => {
//   return useAppStore((state: ApplicationState) => state.userId);
// };

export const useGetExternalUserId = (): string | undefined => {
  return useAppStore((state: ApplicationState) => state.externalUserId);
};

export const useGetUserEmail = (): string | undefined => {
  return useAppStore((state: ApplicationState) => state.email);
};

export const useGetUserAttributes = (): UserAttributes => {
  return useAppStore((state: ApplicationState) => state.userAttributes);
};

export const useChangeAttributes = (): ((data: UserAttributes) => void) => {
  return useAppStore((state: ApplicationState) => state.changeUserAttributes);
};

export const useGetTenantKey = (): string => {
  return useAppStore((state: ApplicationState) => state.tenantKey);
};

export const useGetTenants = (): TenantTuple[] => {
  return useAppStore((state: ApplicationState) => state.tenants);
};

export const useGetTenantFeatures = (): TenantFeature[] => {
  return useAppStore((state: ApplicationState) => state.tenantFeatures);
};

export const useGetIsAuthLoading = (): boolean => {
  return useAppStore((state: ApplicationState) => state.isAuthLoading);
};

export const useGetAuthError = (): ValidateError => {
  return useAppStore((state: ApplicationState) => state.authError);
};

export const useSetAuthLoading = (): ((loading: boolean) => void) => {
  return useAppStore((state: ApplicationState) => state.setAuthLoading);
};

export const useSetAuthError = (): ((error: ValidateError) => void) => {
  return useAppStore((state: ApplicationState) => state.setAuthError);
};

export const useGetUserId = () => {
  return useAppStore((state: ApplicationState) => state.internalUserId);
};

export const useGetDataSharingList = (): DataSharingTenant[] => {
  return useAppStore((state) => state.dataSharingList);
};

export const useSetDataSharingList = (): ((dataSharingList: DataSharingTenant[]) => void) => {
  return useAppStore((state) => state.setDataSharingList);
};

export const useSetDataSharingRequestList = (): ((dataSharingRequestList: DataSharingRequest[]) => void) => {
  return useAppStore((state) => state.setDataSharingRequestList);
};

export const useGetProfilePhotoEffect = () => {
  const setProfilePhoto = useAppStore((state) => state.setProfilePhoto);
  const [error, setError] = useState<any>();

  useEffect(() => {
    UserService.getProfilePhoto()
      .then((data) => {
        if (!data.length) {
          setProfilePhoto('');
        } else {
          setProfilePhoto(data);
        }
      })
      .catch((err: ApiError) => {
        // User has no profile picture
        setError(err.error);
        setProfilePhoto('');
      });
  }, [setProfilePhoto]);
  return [error];
};

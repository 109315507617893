import { Icon, Text, useTheme } from '@ui-kitten/components';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { PillarNames, homePillars } from 'src/core/icons/pillars';

import Brands from 'brands.json';
import DynamicCardLayout from './DynamicCardLayout';
import ForwardIcon from './components/ForwardIcon';
import { PillarDynamicCard } from 'src/screens/main/HomeScreen';
import React from 'react';
import { UIHelper as uh } from 'src/core';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

export interface ScoreDynamicCardProps {
  selectedPillar: PillarDynamicCard;
  onClick: (externalKey: string) => void;
  iconHeight: number;
  iconWidth: number;
}
const ScoreDynamicCard = ({ selectedPillar, onClick, iconHeight, iconWidth }: ScoreDynamicCardProps): JSX.Element => {
  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();

  const styleContainer = StyleSheet.create({
    iconContainer: {
      height: uh.h2DP(iconHeight),
      width: uh.h2DP(iconWidth),
      borderRadius: 999,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: th[`color-${selectedPillar.externalKey}-300-transparent`]
    },
    accuracyIcon: {
      width: 16,
      height: 16,
      marginRight: uh.w2DP(4)
    },
    textContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });

  if (selectedPillar.displayName === '') {
    return <></>;
  }

  const pillarIcon = (
    <View style={styleContainer.iconContainer}>
      {homePillars(
        selectedPillar.externalKey as PillarNames,
        uh.h2DP(iconHeight * 0.65),
        uh.h2DP(iconWidth * 0.65),
        th,
        isLightTheme
      )}
    </View>
  );

  return (
    <DynamicCardLayout
      layoutLevel={'1'}
      style={{ alignItems: 'center' }}
      accessibilityLabel="score dynamic card"
      testID="score-dynamic-card">
      {selectedPillar.default ? (
        <Image
          style={{ height: uh.h2DP(iconHeight), width: uh.h2DP(iconWidth) }}
          source={require('../../../assets/images/brand/chatbot-avatar.png')}
        />
      ) : (
        pillarIcon
      )}
      <View style={styleContainer.textContainer}>
        <Text category="p2" style={{ marginLeft: 12, textTransform: 'capitalize' }}>
          {selectedPillar.displayName}
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon name="loading" pack={Brands.icon_pack} style={styleContainer.accuracyIcon}></Icon>
          <Text category="c1" appearance="hint">
            {Math.floor(selectedPillar.accuracy) || 0}%
          </Text>
          <Text category="h6" style={{ marginHorizontal: 16 }}>
            {selectedPillar.score}%
          </Text>
          <TouchableOpacity
            onPress={() => {
              onClick(selectedPillar.externalKey);
            }}>
            <ForwardIcon color={isLightTheme ? 'black' : 'white'} />
          </TouchableOpacity>
        </View>
      </View>
    </DynamicCardLayout>
  );
};

export default ScoreDynamicCard;

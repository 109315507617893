import * as d3 from 'd3-shape';

import { G, Path, Svg } from 'react-native-svg';
import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

type ChartData = {
  key: string;
  fill: string;
  onPress?: (...args: any[]) => any;
  innerRadius: number;
  outerRadius: number;
  stroke?: string;
};

interface IDonutChartProps extends PropsWithChildren {
  // size of the container
  height: number;
  width: number;

  // Position of the center point in the container
  xPosition: number;
  yPosition: number;

  // Data of the main chart
  data: ChartData[];

  // Data of the background chart
  baseData: ChartData[];

  // Width of the chart itself
  circumference: number;
}

const DonutChart = ({
  height,
  width,
  xPosition,
  yPosition,
  data,
  baseData,
  circumference,
  children
}: IDonutChartProps): JSX.Element => {
  const styles = StyleSheet.create({
    container: {
      height: height,
      width: width
    },
    svg: {
      flex: 1,
      height: height,
      width: width
    },
    centerContainer: {
      flex: 1,
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: '100%'
    },
    childrenContainer: {
      height: circumference + 30,
      width: circumference + 30
    }
  });

  const backgroundARCS: any[] =
    baseData?.map((x: ChartData) => {
      return d3.arc().outerRadius(x.innerRadius).innerRadius(x.outerRadius).padAngle(0.05);
    }) ?? [];

  const arcs: any[] = data.map((x: ChartData) => {
    return d3.arc().outerRadius(x.outerRadius).innerRadius(x.innerRadius).padAngle(0.05);
  });

  const degressToRadians = (degress: number) => {
    return (degress * Math.PI) / 180;
  };

  const pieSlices = d3
    .pie()
    .value(circumference)
    .sort(() => 1)
    .startAngle(degressToRadians(-(180 / baseData.length)))
    .endAngle(Math.PI * 2)(baseData.map(() => 0));

  return (
    <View style={styles.container}>
      <Svg style={styles.svg}>
        <G x={xPosition} y={yPosition}>
          {pieSlices.map((slice, index) => {
            const { key, fill, onPress, stroke } = baseData[index];
            return (
              <Path
                key={key}
                onPress={onPress}
                fill={fill}
                d={backgroundARCS[index](slice)}
                stroke={stroke}
                strokeWidth={2}
              />
            );
          })}
          {pieSlices.map((slice, index) => {
            const { key, fill, onPress } = data[index];
            return <Path pointerEvents="none" key={key} onPress={onPress} fill={fill} d={arcs[index](slice)} />;
          })}
        </G>
      </Svg>
      <View style={styles.centerContainer} pointerEvents="none">
        <View style={styles.childrenContainer}>{children}</View>
      </View>
    </View>
  );
};

export default DonutChart;

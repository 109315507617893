import { Image, ImageRequireSource } from 'react-native';

import Brands from 'brands.json';
/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';

/**
 * https://akveo.github.io/react-native-ui-kitten/docs/guides/icon-packages#3rd-party-icon-packages
 */

const IconProviderWeb = (source: ImageRequireSource) => ({
  toReactElement: (props: any) => {
    return <Image {...props} resizeMode="contain" source={source} />;
  }
});

// TODO: Add HealthIcons here if not SVG
export const KalibraCustomIconPack = {
  name: Brands.icon_pack,
  icons: {
    'apple-light': IconProviderWeb(require('../../../assets/images/icon-apple.png')),
    nourish: IconProviderWeb(require('../../../assets/images/brand/nourish.png')),
    move: IconProviderWeb(require('../../../assets/images/brand/move.png')),
    diet: IconProviderWeb(require('../../../assets/images/diet.png')),
    lifestyle: IconProviderWeb(require('../../../assets/images/brand/move.png')),
    supplement: IconProviderWeb(require('../../../assets/images/supplement.png')),
    connect: IconProviderWeb(require('../../../assets/images/brand/connect.png')),
    reflect: IconProviderWeb(require('../../../assets/images/brand/reflect.png')),
    grow: IconProviderWeb(require('../../../assets/images/brand/grow.png')),
    rest: IconProviderWeb(require('../../../assets/images/brand/rest.png')),
    loading: IconProviderWeb(require('../../../assets/images/loading.png')),
    'apple-watch': IconProviderWeb(require('../../../assets/images/apple-watch.png')),
    headspace: IconProviderWeb(require('../../../assets/images/headspace.png'))
  }
};

import { CheckActiveSubscription, SubscriptionInfo } from 'src/core/types/SubscriptionInfo';
import { LatestDataCount, LatestDataCountType } from 'src/core/types/UserAttributes';
import { TenantFeature, UserAttributes, UserSettings } from 'src/core';

import { AhiScanType } from 'src/core/hooks/useAhiSubscriptions';
import { ApiResponse } from 'src/core/types/ApiResponse';
import { BackendApi } from '../../shared';
import { DataSharingTenant } from 'src/core/types/DataSharingTenant';
import { HealthMarkerLatest } from 'src/core/types/HealthMarkerReport';
import { UserService } from '../model';
import { DataSharingRequest } from 'src/core/types/DataSharingRequest';

export const WebUserService: UserService = {
  getUserInfo: (): Promise<UserAttributes> => {
    return BackendApi.get('/users').then((response) => response.data);
  },
  addUser: (attributes: UserAttributes): Promise<UserAttributes> => {
    return BackendApi.post('/users', attributes).then((response) => response.data);
  },
  getExtraUserAttributes: async (): Promise<Partial<UserAttributes>> => {
    const markersTuple: [string, string][] = [
      ['Height', 'height'],
      ['Bodyweight', 'weight'],
      ['Smoker', 'smoker'],
      ['BloodPressureMedication', 'bloodpressureMedication'],
      ['Hypertension', 'hypertension'],
      ['Diabetic', 'diabetic'],
      ['ActivityLevel', 'activityLevel']
    ];
    const markers = markersTuple.map((marker) => marker[0]);

    const markersResponse = await BackendApi.get(
      `/health-markers/data/latest-health-markers?healthMarkerExternalKeys=${markers.join(',')}`
    );

    const extraMarkerInfo = markersResponse.data
      .filter((marker: HealthMarkerLatest) => markers.includes(marker.externalKey))
      .reduce(
        (prev: Partial<UserAttributes>, curr: HealthMarkerLatest) => ({
          ...prev,
          [markersTuple.find((marker) => marker[0] === curr.externalKey)?.[1] ?? curr.externalKey]: curr.latestValue
        }),
        {}
      );

    return extraMarkerInfo;
  },
  deleteUser: (): Promise<boolean> => {
    return BackendApi.delete('/users')
      .then((response) => response.status === 200)
      .catch(() => Promise.resolve(false));
  },
  getTenantsFeatures: (): Promise<TenantFeature[]> => {
    return BackendApi.get('/tenants/features').then((response) => response.data.features);
  },
  getDataSharingList: (): Promise<DataSharingTenant[]> => {
    return BackendApi.get('tenants/list-connected').then((response) => response.data.userDataSharingList);
  },
  getDataSharingRequestList: (): Promise<DataSharingRequest[]> => {
    return BackendApi.get('tenants/list-sharing-request').then((response) => response.data.userDataSharingList);
  },
  cancelDataSharing: (tenantKey: string): Promise<void> => {
    return BackendApi.patch('/tenants/set-user-data-sharing-status', { tenantKey: tenantKey }).then(
      (response) => response.data
    );
  },
  acceptDataSharing: (proUserId: string, tenantKey: string, isAllowed: boolean): Promise<void> => {
    return BackendApi.post('/tenants/connect', {
      proUserId: proUserId,
      tenantKey: tenantKey,
      isAllowed: isAllowed
    }).then((response) => response.data);
  },
  requestAhiScan: (scanType: AhiScanType): Promise<boolean> => {
    return BackendApi.post('/ahi/request-ahi-scan', { scanType: scanType }).then((response) => response.data);
  },
  requestScanPayment: (): Promise<boolean> => {
    return BackendApi.post('/ahi/request-scan-payment')
      .then((response) => response.data)
      .catch(() => Promise.resolve(false));
  },
  getLatestDataCount: (): Promise<LatestDataCount> => {
    return BackendApi.get('/users/latest-data-count').then((response) => response.data);
  },
  updateLatestDataCount: (type: LatestDataCountType): Promise<boolean> => {
    return BackendApi.patch(`/users/latest-data-count/${type}`).then((response) => response.data);
  },
  getUserSettings: (): Promise<UserSettings> => {
    return BackendApi.get('users/settings').then((response) => response.data);
  },
  setUserSettings: (settings: UserSettings): Promise<any> => {
    return BackendApi.post('users/settings', settings as any).then((response) => response.data);
  },
  getProfilePhoto: (): Promise<string> => {
    return BackendApi.get('/users/get-profile-photo').then((response) => response.data);
  },
  removeProfilePhoto: (): Promise<void> => {
    return BackendApi.delete('/users/remove-profile-photo').then((response) => response.data);
  },
  uploadProfilePhoto: (fileName: string, document: string, filetype: string): Promise<void> => {
    return BackendApi.post('users/upload-profile-photo', {
      filename: fileName,
      document: document,
      filetype: filetype
    });
  },
  setUserSubscription: (userSubscription: SubscriptionInfo): ApiResponse<any> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.put('/subscriptions/set-subscription', userSubscription, { signal: abortController.signal })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  checkUserSubscription: (platform: string): ApiResponse<CheckActiveSubscription> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`/subscriptions/has-active-subscription?platform=${platform}`, {
        signal: abortController.signal
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  }
};

import { Layout, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';

import ForwardIcon from './icons/ForwardIcon';
import { NotificationMessage } from 'src/core/types/Notification';
import React from 'react';
import moment from 'moment';
import { UIHelper as uh } from '../../../core';

//props
interface NotificationItemProps extends ViewProps {
  notiMessage: NotificationMessage;
  onReadNotification: () => void;
}

// styles
const styleContainer = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    borderWidth: uh.w2DP(0.3),
    borderRadius: 8,
    alignItems: 'center',
    padding: 16,
    columnGap: uh.w2DP(8)
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    height: 'auto',
    fontSize: 14
  },
  contentContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    columnGap: uh.w2DP(8)
  },
  date: {
    alignSelf: 'flex-start',
    paddingTop: uh.h2DP(8)
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  }
});

const NotificationItem = ({ notiMessage, onReadNotification }: NotificationItemProps) => {
  const th = useTheme();
  return (
    <Layout level="2" style={{ marginTop: 12, borderRadius: 8 }}>
      <View style={[styleContainer.container, notiMessage.mark && { opacity: 0.5 }]} key={notiMessage.id}>
        <View style={styleContainer.contentContainer}>
          <Text category={notiMessage.mark ? 'c1' : 'label'} style={[styleContainer.content]}>
            {notiMessage.content}
          </Text>

          <TouchableOpacity
            disabled={notiMessage.mark}
            onPress={onReadNotification}
            style={{ alignSelf: 'flex-start' }}>
            <ForwardIcon color={th['text-basic-color']} />
          </TouchableOpacity>
        </View>
        <View style={styleContainer.dateContainer}>
          <Text category="p2" style={[styleContainer.date, { color: th['color-basic-500'] }]}>
            {moment(notiMessage.sendAt ?? notiMessage.createdOn)
              .tz(notiMessage.timezone ?? 'UTC')
              .format('DD MMM YYYY  HH:MM')}
          </Text>
          <Text category="p2" style={[styleContainer.date, { color: th['color-basic-500'] }]}>
            {notiMessage.timezone ?? 'UTC'}
          </Text>
        </View>
      </View>
    </Layout>
  );
};

export default NotificationItem;

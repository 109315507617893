import 'react-native-get-random-values';

import * as Animatable from 'react-native-animatable';

import { Divider, Text, useTheme } from '@ui-kitten/components';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { PillarNames, chatFooterPillars } from 'src/core/icons/pillars';
import React, { memo } from 'react';

import { UIHelper as uh } from '../../core';
import { useGetPillarCategories } from 'src/core/hooks/usePillarCategories';
import { useIsLightColorScheme } from 'src/core/hooks/useIsLightColorScheme';

interface ChatFooterProps {
  handleTopicChange: (pillarIndex: number) => void;
  activePillar: number;
  disableFooter: boolean;
}

const ChatFooter = ({ handleTopicChange, activePillar, disableFooter }: ChatFooterProps) => {
  const th = useTheme();
  const isLightTheme = useIsLightColorScheme();
  const pillarCategories = useGetPillarCategories();

  const styles = StyleSheet.create({
    container: {
      height: uh.h2DP(40),
      backgroundColor: 'transparent',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginBottom: uh.h2DP(10),
      paddingHorizontal: uh.h2DP(16),
      gap: uh.h2DP(8)
    },
    text: {
      fontStyle: 'italic',
      textAlign: 'center',
      backgroundColor: 'transparent',
      fontSize: 14
    }
  });

  const ACTIVE_PILLAR_COLOR = (pillar: string) => th[`color-${pillar.toLowerCase()}-${isLightTheme ? 600 : 400}`];
  const INACTIVE_PILLAR_COLOR = (pillar: string) => th[`color-${pillar.toLowerCase()}-${isLightTheme ? 400 : 600}`];

  if (activePillar === -1 || pillarCategories.length === 0) {
    return <View style={styles.container} />;
  }
  return (
    <Animatable.View animation={'slideInUp'} duration={250}>
      <Divider
        style={{
          backgroundColor: th['color-primary-transparent-500'],
          alignSelf: 'center',
          marginVertical: uh.h2DP(6),
          width: '90%'
        }}
      />
      <View style={styles.container}>
        <Text style={{ fontSize: 14 }} appearance="hint">
          Chat about
        </Text>
        <FlatList
          contentContainerStyle={[styles.container, { gap: uh.h2DP(16) }]}
          data={pillarCategories}
          horizontal
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={{
                  borderBottomWidth: activePillar === item.id ? 1 : 0,
                  borderBottomColor: ACTIVE_PILLAR_COLOR(item.name)
                }}
                key={item.id}
                onPress={() => {
                  if (!disableFooter) {
                    handleTopicChange(item.id);
                  }
                }}>
                {chatFooterPillars(
                  item.name.toLowerCase() as PillarNames,
                  activePillar == item.id
                    ? ACTIVE_PILLAR_COLOR(item.name)
                    : !disableFooter
                    ? INACTIVE_PILLAR_COLOR(item.name)
                    : th['color-basic-disabled']
                )}
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </Animatable.View>
  );
};

export default memo(ChatFooter);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { ConnectIcon } from './connect';
import { GrowIcon } from './grow';
import { MoveIcon } from './move';
import { NourishIcon } from './nourish';
import React from 'react';
import { ReflectIcon } from './reflect';
import { RestIcon } from './rest';
import { SvgIconProp } from 'src/core/types/ScanIcons';

export type PillarNames = 'rest' | 'nourish' | 'move' | 'connect' | 'grow' | 'reflect';

interface PillarIconProps extends SvgIconProp {
  name: PillarNames;
}

export const PillarIcon = ({ name, color, height, width, strokeWidth }: PillarIconProps) => {
  switch (name) {
    case 'rest': {
      return RestIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    }
    case 'nourish': {
      return NourishIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    }
    case 'move': {
      return MoveIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    }
    case 'connect': {
      return ConnectIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    }
    case 'grow': {
      return GrowIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    }
    case 'reflect':
      return ReflectIcon({ color: color, height: height, width: width, strokeWidth: strokeWidth });
    default:
      return <></>;
  }
};

export const homePillars = (
  name: PillarNames,
  height: number,
  width: number,
  th: Record<string, string>,
  isLightTheme: boolean
) => {
  return (
    <PillarIcon name={name} height={height} width={width} color={th[`color-${name}-${isLightTheme ? '700' : '900'}`]} />
  );
};

export const chatFooterPillars = (name: PillarNames, color: string) => {
  return <PillarIcon name={name} height={28} width={28} color={color} />;
};

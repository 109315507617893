import 'react-native-gesture-handler'; // Import before React. iOS/Android requirement.

import * as SplashScreen from 'expo-splash-screen';

import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { Config, KalibraDesign, useAppInitializer } from './src/core';
import { Image, LogBox, Platform, View, StyleSheet, Text, TextInput } from 'react-native';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useIsUserAutoLogin, useUserInitializer, useUserLoginInitializer } from 'src/core/hooks/useUser';

import AppRootView from './src/components/AppRootView';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import Brands from 'brands.json';
import ErrorScreen from 'src/screens/error';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { InitErrorCode } from 'src/core/types/InitError';
import { KalibraCustomIconPack } from './src/core/constants/KalibraCustomIconPack';
import Splash from 'assets/images/brand/splash.png';
import ThemeInit from 'src/components/shared/ThemeInit';
import { useAfterLoginInitializer } from 'src/core/hooks/useAfterLoginInitializer';
import { useAnalyticsInitializer } from 'src/core/hooks/useAnalyticsInitializer';
import { useAppStore } from 'src/core/store';
import { useInitAppThemeLegacy } from 'src/core/hooks/useAppTheme';
import { useInitDataSharingList } from 'src/core/hooks/tenant/useInitDataSharingList';
import { useInitNotifications } from 'src/core/hooks/useNotifications';
import { useInitTelemetry } from 'src/core/hooks/useTelemetry';
import { useMarkersInitializer } from 'src/core/hooks/useMarkers';
import { useMultiScanInitializer } from 'src/core/hooks/useMultiScan';
import { useNeedUpdateApp } from 'src/core/hooks/useNeedUpdateApp';
import { useNotitficationMessage } from 'src/core/hooks/useNotificationMessage';
import { usePillarCategories } from 'src/core/hooks/usePillarCategories';
import { useSetDefaultServer } from 'src/core/hooks/useSetDefaultServer';
import { useWearableInitializer } from 'src/core/hooks/useWearableSdk';
import { UIHelper as uh } from 'src/core';
import { useInitDataSharingRequestList } from 'src/core/hooks/tenant/useInitDataSharingRequestList';

// set FontSizeMultiplier = 1
(Text as any).defaultProps = { ...((Text as any).defaultProps || {}), maxFontSizeMultiplier: 1 };
(TextInput as any).defaultProps = { ...((TextInput as any).defaultProps || {}), maxFontSizeMultiplier: 1 };

if (!Config.DEBUG_MODE) LogBox.ignoreAllLogs();

export default function App(): JSX.Element {
  // styles
  const styleContainer = StyleSheet.create({
    splashContainer: {
      margin: 'auto',
      width: uh.currentViewPort(),
      height: '100%'
    },
    splash: { width: '100%', height: '100%' }
  });
  // call custom initializer hook
  const appPreInit = useAppInitializer();
  const isUserAutoLogin = useIsUserAutoLogin();
  const themeType = useAppStore((state) => state.themeType);
  const setTheme = useAppStore((state) => state.setTheme);
  const [isSplashScreenFinished, setIsSplashScreenFinished] = useState<boolean>(
    Platform.OS === 'web' || Brands.use_animated_splash !== 'true'
  );

  const initThemeLegacy = useInitAppThemeLegacy();

  useNeedUpdateApp();

  useSetDefaultServer();

  useUserInitializer();

  useWearableInitializer();

  const isAppInitComplete = useAfterLoginInitializer();
  useMarkersInitializer();

  useNotitficationMessage();

  useAnalyticsInitializer();

  useInitTelemetry();
  useMultiScanInitializer();

  useInitDataSharingList();

  useInitDataSharingRequestList();

  usePillarCategories();

  const isLoginModuleInit = useUserLoginInitializer();

  useEffect(() => {
    initThemeLegacy(setTheme);
  }, [initThemeLegacy, setTheme]);

  useInitNotifications();

  useEffect(() => {
    if (appPreInit.isInitComplete && isLoginModuleInit) {
      try {
        SplashScreen.hideAsync();
      } catch (error) {
        //Not important
      }
    }
  }, [appPreInit.isInitComplete, isLoginModuleInit]);

  if (
    appPreInit.initErrorCode !== InitErrorCode.OK ||
    (isAppInitComplete !== null && isAppInitComplete !== InitErrorCode.OK)
  ) {
    return <ErrorScreen code={appPreInit.initErrorCode} />;
  }

  if (!appPreInit.isInitComplete || !isLoginModuleInit || isUserAutoLogin || !isSplashScreenFinished) {
    if (Platform.OS !== 'web' && Brands.use_animated_splash === 'true') {
      const LottieView = lazy(() => import('lottie-react-native'));
      return (
        <Suspense fallback={<Image style={{ width: '100%', height: '100%' }} source={Splash} />}>
          <LottieView
            style={{ width: '100%', height: '100%' }}
            source={require('assets/images/brand/animated-splash-screen')}
            autoPlay
            renderMode={'HARDWARE'}
            hardwareAccelerationAndroid={true}
            loop={false}
            onAnimationFinish={() => setIsSplashScreenFinished(true)}
            resizeMode={Platform.OS === 'android' ? 'cover' : 'contain'}
          />
        </Suspense>
      );
    }
    return (
      <View style={styleContainer.splashContainer}>
        <Image style={styleContainer.splash} source={Splash} />
      </View>
    );
  }

  // render application entry point
  return (
    <>
      <IconRegistry icons={[EvaIconsPack, KalibraCustomIconPack]} />
      <ApplicationProvider {...KalibraDesign} theme={themeType}>
        <BottomSheetModalProvider>
          <ThemeInit />
          <AppRootView />
        </BottomSheetModalProvider>
      </ApplicationProvider>
    </>
  );
}
